<!--
 * @Author: Do not edit
 * @Date: 2021-03-16 09:31:58
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 11:37:39
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\trend.vue
-->
<template>
  <div class="trend">
    <div class="banner">
      <div class="txt-inner wow bounceInRight">
        <p>洞悉行业趋势，引领行业潮流</p>
        <a href="/Contact">立即体验</a>
      </div>
    </div>
    <div class="cent">
      <div class="txt-cent">    
        <div class="inner-title">公司动态</div>
        <articleList :list="companyLis"></articleList>                                                                                                          
        <!-- <el-tabs v-model="activeName">
          <el-tab-pane label="参会动态" name="1">
            <articleList :list="companyLis"></articleList>
          </el-tab-pane>
          <el-tab-pane label="重要合作" name="2">
            <nodata></nodata>
          </el-tab-pane>
          <el-tab-pane label="重大客户签约" name="3">
            <nodata></nodata>
          </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import articleList from "./../components/articleList.vue";
// import nodata from "./../components/noData.vue";
import {canhuiList} from "./article/article";

export default {
  name: "trend",
  components: {
    articleList
  },
  data() {
    return {
      activeName:"1",
      companyLis: []
    };
  },
  mounted() {
    this.companyLis = canhuiList;
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-scroll {
  border-bottom: 2px solid #e8ecf2;
}
/deep/ .el-tabs__item {
  font-size: 24px;
  height: 60px;
  line-height: 60px;
}
/deep/ .el-tabs__nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
/deep/ .el-tabs__active-bar {
  height: 0;
}
/deep/ .el-tabs__item.is-active {
  border-bottom: 2px solid #409eff;
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding: 56px 0 50px 0;
  text-align:center;
}
.trend{
  background:#f1f6ff;
  padding-bottom:60px;
}
.banner {
  background: url("~@/assets/images/banner-trend.jpg") no-repeat top center;
  height:578px;
  position: relative;
  .txt-inner {
    text-align: center;
    padding-top:240px;
    h3 {
      font-size: 48px;
      margin: 0;
    }
    p {
      font-size: 48px;
      text-align: center;
      margin: 20px 0;
    }
    a{
      display: inline-block;
      width:335px;
      height:50px;
      line-height: 50px;
      color:#fff;
      background:#0183de;
      font-size:18px;
      border-radius:5px;
      font-size:18px;
      margin-top:30px;
      text-decoration: none;
    }
  }
}
.bannertxt {
  position: absolute;
  top: 250px;
  left: 55%;
  margin-left: -185px;
}
.txt-cent {
  width: 1300px;
  margin: 0 auto;
  color: #333;
  font-size: 12px;
  text-align: left;
  margin-top: 0;
}
@media (max-width: 750px) {
  .banner {
    height: 158px;
    overflow: hidden;
    background-size: 210%;
    background-position: top center;
    background-color:#fff;
    .txt-inner {
      padding-top:20px;
      h3 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 20px;
        margin-top: 18px;
      }
      a{
        width: 127px;
        height: 30px;
        line-height: 30px;
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
    /deep/ .el-tabs__item{
    font-size:16px;
    font-weight: bold;
    line-height:66px;
  }
  .inner-title {
    padding-top: 30px;
    font-size: 20px;
    padding-bottom: 20px;
  }
  .txt-cent {
    width: 98%;
    margin: 0 auto;
  }
}
</style>
