<!--
 * @Author: Do not edit
 * @Date: 2021-07-22 14:04:36
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 11:38:24
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\articleList.vue
-->
<template>
  <div class="article-list">
    <dl v-for="(item,index) in list" :key="index" @click="goInfo(item)">
      <dt><img :src="item.src" :alt="item.name" /></dt>
      <dd class="col-name">{{ item.name }}</dd>
      <dd class="col-time">{{ item.time }}</dd>
    </dl>
  </div>
</template>
<script>

export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
    };
  },
  methods: {
    goInfo(item) {
      this.$router.push({ name: "Article",query: {id:item.id}})
    }
  }
};
</script>
<style lang="scss" scoped>
dd{
  margin:0
}
.article-list {
  display:flex;
   flex-wrap: wrap;
  dl {
    width:390px;
    height: 420px;
    background: #fff;
    border-radius: 5px;
    display: inline-block;
    margin:15px;
    border-radius:5px;
    overflow:hidden;
    cursor:pointer;
  }
  img{
    width:100%;
  }
  .col-name {
    line-height: 40px;
    text-align: center;
    border-radius: 0 0 5px 5px;
    font-size:24px;
    text-align:left;
    padding:15px 20px;
    height:90px;
    text-overflow:ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -webkit-line-clamp:2;
    overflow: hidden;
  }
  .col-time{
    text-align:right;
    font-size:16px;
    padding:15px 20px 0;
  }
}
@media (max-width: 750px) {
  .article-list {
    dl {
      width:46%;
      height: auto;
      margin: 0 0 20px 10px;
    }
    .col-name{
      padding:5px 10px;
      font-size:18px;
      line-height:24px;
      height:56px;
    }
    .col-time{
      text-align: right;
      font-size: 14px;
      padding: 10px 10px 20px 0;
    }
  }
}
</style>
