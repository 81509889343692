<!--
 * @Author: Do not edit
 * @Date: 2021-03-16 09:31:58
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 11:32:39
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\Case.vue
-->
<template>
  <div class="case">
    <div class="banner">
      <div class="txt-inner  wow bounceInRight">
        <p>观点的碰撞 思维的交流 灵魂的沟通</p>
        <a href="/Contact">立即体验</a>
      </div>
    </div>
    <div class="cent">
      <el-tabs v-model="activeName">
        <el-tab-pane label="专业文章发表" name="1">
          <articleList :list="companyLis"></articleList>
        </el-tab-pane>
        <el-tab-pane label="数字化讲座录屏" name="2">
          <articleList :list="videoLis"></articleList>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import articleList from "./../components/articleList.vue";
import {zhuanyeList,shipinList} from "./article/article";

export default {
  name: "case",
  components: {
    articleList
  },
  data() {
    return {
      activeName: "1",
      companyLis: [],
      videoLis:[]
    };
  },
  mounted() {
    this.companyLis = zhuanyeList;
    this.videoLis = shipinList;
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
<style lang="scss" scoped>
.case {
  background: #f1f6ff;
}
/deep/ .el-tabs__nav-scroll {
  // border-bottom: 2px solid #e8ecf2;
  margin: 0 70px;
}
/deep/ .el-tabs__item {
  font-size: 24px;
  height: 60px;
  line-height: 60px;
}
/deep/ .el-tabs__nav {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
/deep/ .el-tabs__active-bar {
  height: 0;
}
/deep/ .el-tabs__item.is-active {
  border-bottom: 2px solid #409eff;
}
.banner {
  background: url("~@/assets/images/banner-case.jpg") no-repeat top center;
  height: 638px;
  position: relative;
  .txt-inner {
    text-align: center;
    padding-top: 230px;
    z-index: 10;
    p {
      font-size: 48px;
      text-align: center;
      margin: 20px 0;
    }
    a {
      display: inline-block;
      width: 335px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background: #0183de;
      font-size: 18px;
      border-radius: 5px;
      font-size: 18px;
      margin-top: 30px;
      text-decoration: none;
    }
  }
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding-top: 66px;
}
.cent {
  padding-bottom: 120px;
  color: #333;
  text-align: left;
  width: 1300px;
  margin: 50px auto 0;
}
/deep/ .article-list {
  margin-top: 30px;
}
dd {
  margin-left: 0;
}
.head {
  .col-imgs {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
    background: #f3f3f3;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    img {
      width: 94px;
      height: auto;
    }
  }
  .col-title {
    font-size: 22px;
    vertical-align: middle;
    font-weight: 400;
  }
  .col-info {
    margin: 30px auto 0;
    line-height: 24px;
    font-size: 12px;
    color: #333;
  }
}
.content-lis {
  display: flex;
  width: 90%;
  margin: 50px auto 0;
  align-items: center;
  justify-content: space-between;
  .section {
    width: 45%;
    height: 500px;
    padding: 20px;
    border-radius: 5px;
    line-height: 24px;
    font-size: 14px;
    h5 {
      font-size: 16px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 10px;
    }
    dl {
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
    }
    dt {
      font-weight: 500;
      margin-bottom: 10px;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 9px;
        border-radius: 50%;
      }
    }
    dd {
      font-size: 12px;
    }
  }
  .old {
    background: #fcfcfc;
    border: 1px solid #e8ecf2;
    dt:before {
      background: #0183de;
    }
  }
  .new {
    background: #f8fbff;
    border: 1px solid #99d7bc;
    color: #26c393;
    dt:before {
      background: #99d7bc;
    }
  }
}
@media (max-width: 750px) {
  .banner {
    height: 202px;
    background-size: 274%;
    .txt-inner {
      width: 100%;
      padding-top:20px;
      p {
        font-size: 22px;
        margin-top:60px;
        text-align: center;
        letter-spacing: 0;
      }
      a {
        width: 127px;
        height: 30px;
        line-height: 30px;
        margin-top: 0;
        font-size: 14px;
      }
    }
    &::after {
      height: 90px;
    }
  }
  .inner-title {
    padding-top: 30px;
    font-size: 20px;
    padding-bottom: 20px;
  }
  .cent {
    width: 96%;
    margin-top:20px;
    padding-bottom:50px;
  }
  /deep/ .el-tabs__nav{
    padding:0 20px;
  } 
  /deep/ .el-tabs__nav-scroll{
    margin: 0;
  }
  /deep/ .el-tabs__item{
    font-size:16px;
    font-weight: bold;
    line-height:66px;
  }
}
</style>
